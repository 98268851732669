<template>    
    <div class="main-container">
        <h1 class="title-solo">Speakers & Conferences</h1>
        <section class="table-container">

            <!-- ROW HEADER -->
            <div class="row row-header">
                <div class="table-dates">Dates</div>
                <div class="table-title">Title</div>
                <div class="table-speaker">Speaker</div>
                <div class="table-company">Company</div>
            </div>
            <div class="row-container" v-for="(item, idx) in speakers" :key="idx">
                <div class="row" >
                    <div class="table-dates">
                        <p class="table-date">{{ item.date }}</p>
                        <p class="table-time">{{ item.time }} CET</p>
                        
                    </div>
                    <div class="table-title">
                        <h2 v-html="item.title"></h2>
                        <p class="french">In French</p>
                    </div>
                    <div class="table-speaker">
                        <img :src="item.avatar" alt="avatar" class="avatar"/>
                        <h3 v-html="item.speakerName"></h3>
                    </div>
                    <div class="table-company">
                        <a :href="item.url" target="_blank">
                            <img :src="item.company" alt="company logo" />
                        </a>
                    </div>
                </div>
                <div class="row-sep" v-if="!item.hideSep"></div>
            </div>
            <div class="btn-container">
                <a class="btn" href="https://cryptovirtua.my-virtual-convention.com/login" target="_blank">Attend the Event</a>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name:"Speakers",
    data() {
        return {
            speakers :
                [
                    {
                        title: 'Blockchain pour les entreprises : perspectives et cas d’usage',
                        date : 'May 24',
                        time : '9H15',
                        avatar : require('@/assets/speakers/Najah_Quantum_BCS_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Quantum_BCS_v2.png'),
                        speakerName : 'Najah Naffah',
                        url: "https://quantumbcs.io/en/"
                    },
                    {
                        title: 'L’usage du Metaverse. Pour qui ? Pour quoi faire ? Dans quel intérêt ?',
                        date : 'May 24',
                        time : '10h00',
                        avatar : require('@/assets/speakers/Neal_Robert_Bem_Builders_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Bem_Builders_400x230_v2.png'),
                        speakerName : 'Neal Robert',
                        url: "https://www.bem.builders/"
                    },
                    {
                        title: 'Pourquoi les NFT seront incontournables dans le Web de demain ?',
                        date : 'May 24',
                        time : '10H45',
                        avatar : require('@/assets/speakers/Aymeric_Orphen_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Orphen_v2.png'),
                        speakerName : 'Aymeric Labedan',
                        url: "https://orphen-nft.com/"
                    },
                    {
                        title: 'Le Metaverse, un véritable changement de paradigme ?',
                        date : 'May 24',
                        time : '12H15',
                        avatar : require('@/assets/speakers/Emmanuel_France_Meta_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_France_Meta_400x230px_v2.png'),
                        speakerName : 'Emmanuel Moyrand',
                        url: "https://www.francemeta.org/"
                    },
                    {
                        title: 'Diplômes, Micro-Certifications et Badges sur la Blockchain',
                        date : 'May 24',
                        time : '14H00',
                        avatar : require('@/assets/speakers/Vincent_EvidenZ_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Evidenz_400x230_v2.png'),
                        speakerName : 'Vincent Langard',
                        url: "https://www.evidenz.io/"
                    },
                    {
                        title: 'Comment utiliser la Blockchain pour faire de la signature électronique ?',
                        date : 'May 24',
                        time : '14H45',
                        avatar : require('@/assets/speakers/Alain_Broustail_Coeaxy_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_coexya_v2.png'),
                        speakerName : 'Alain Broustail',
                        url: "https://www.coexya.eu/"
                    },
                    {
                        title: 'L’utilisation de NFT non spéculatifs dans le monde du sport',
                        date : 'May 24',
                        time : '15H30',
                        avatar : require('@/assets/speakers/Vincent_Ricard_MEDALVERSE_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Medalverse_400x230_v2.png'),
                        speakerName : 'Vincent Ricard',
                        url: "https://medalverse.xyz/"
                    },
                    {
                        title: 'Blockchain privée vs Blockchain publique. Qui va gagner "the" bataille ?',
                        date : 'May 24',
                        time : '16H15',
                        avatar : require('@/assets/speakers/Hammed_Palo_IT_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Palo_IT_400x230_v2.png'),
                        speakerName : 'Hammed Ramdani',
                        url: "https://www.palo-it.com/fr/",
                        hideSep : true
                    },
                    {
                        title: 'L’usage de ICO comme moyen de financement et d’utilisation de paiements dans le Web3.0',
                        date : 'May 24',
                        time : '17H00',
                        avatar : require('@/assets/speakers/Francois_Talium_v1.png'),
                        company : require('@/assets/logos-exhibitors/Logo_Talium_400x230_v2.png'),
                        speakerName : 'François de Chezelles',
                        url: "https://talium.fr/fr/"
                    },
                    // {
                    //     title: 'L\'Automobile et le Metaverse. Pourquoi ?',
                    //     date : 'October 28',
                    //     time : '17h00',
                    //     avatar : require('@/assets/speakers/Eric_Pilat_Cara7_v1.png'),
                    //     company : require('@/assets/logos-exhibitors/Logo_Cara7_400x230_v2.png'),
                    //     speakerName : 'Eric Pilat',
                    //     url: "https://www.cara7.com/",
                    //     hideSep : true
                    // }
                ]
        }
    }
}
</script>

<style lang="scss" scoped>
 .table-container {
        width:1400px;
        max-width: 98%;
        margin: 80px auto;
    }

    .row {
        display: flex;
        width:100%;
        margin:10px 0;
    }

    .table-dates, .table-title, .table-speaker, .table-company  {
        width:calc(25% - 10px);
        margin:0 5px;
        border:1px solid $main-color;
        padding:20px;
        display: flex;
        align-items:center;
        justify-content: center;
        flex-direction: column;
    }

    .row h2 {
        text-align: center;
        color:$text-color;
        font-size:20px;
        font-weight: 400; 
        letter-spacing: 1.5px;
        margin:5px 0 20px 0;
        text-transform: uppercase;
    }

    .row h3 {
        text-align: center;
        color:$text-color;
        font-size:18px;
        font-weight: 700; 
        letter-spacing: 1.5px;
        margin:0;
        text-transform: uppercase;
        margin-top:10px;
    }

    .row-header .table-dates, .row-header  .table-title, .row-header  .table-company, .row-header  .table-speaker {
        background:$main-color;
        color:#fff;
        font-size:20px;
        letter-spacing: 1.5px;
        font-weight: 700;
        text-align: center;
        height:50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
    }

    .row-sep {
        display: none;
    }

    .table-date {
        font-weight:700;
        font-size:30px;
        text-align: center;
        margin:0 0 20px 0;
        color:$text-color;
    }

    .table-time {
        font-weight:700;
        font-size: 30px;
        text-align: center;
        margin:0 0 20px 0;
        color:$text-color;
    }

    img {
        max-width: 90%;
    }

    .avatar {
        width:200px;
    }

    p.french {
        text-align: center;
        font-weight: 400;
        color:$text-color;
        font-style: italic;
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

    a {
        display: flex;
        justify-content: center;
        cursor: pointer;
    }
   

    @media screen and (max-width:1024px) {

        .row-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .row {
            flex-direction: column;
            margin:40px 10px;
        }

        .row .table-dates, .row .table-title, .row .table-company, .row .table-speaker {
            width:calc(100% - 40px);
            margin:5px 0;
        }

        .row .table-title p {
            text-align: center;
        }

        .row-header  {
            display:none;
        }

        .row-sep {
            display: flex;
            justify-content: center;
            height:10px;
            width:10px;
            border-radius: 50%;
            background: $main-color;
        }

        .table-container {
            display: flex;
            flex-direction:column;
            align-items: center;
        }
    }

</style>